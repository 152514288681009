export const API_BASE_URL = 'https://stg-api.textalize.ai';
export const GOOGLE_CLIENT_ID = '198586848581-i0d7lcs08iki6gtfn4inrj4qgk84e8c1.apps.googleusercontent.com';

export const GOOGLE_SCOPES = [
  "https://www.googleapis.com/auth/meetings.space.readonly",
  "https://www.googleapis.com/auth/drive.readonly",
  "https://www.googleapis.com/auth/calendar.readonly"
]

export const ZOOM_AUTHORIZATION_URL = 'https://zoom.us/oauth/authorize?response_type=code&client_id=1hFuh9ZTwqd0SHq5ixcDA&redirect_uri=https://stg.textalize.ai/connect-zoom/'

export const ENVIRONMENT = 'stage'
